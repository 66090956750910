import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../Global/makeRequest';
import { Container, Row, Col, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import MenuNav from '../Global/MenuNav';
import { Gear } from 'react-bootstrap-icons';
import SettingUser from './SettingUser';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Auth/authContext';
import { HandleLogout } from '../Global/Logout';

function Utenti() {
    const [currentUser, setCurrentUser] = useState(null)
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext)
    const [listautenti, setListaUtenti] = useState([]);
    const [listaproprieta, setListaProprieta] = useState([])
    const [handleNew, setHandleNew] = useState(true);
    const [selectedUser, setSelectedUser] = useState(0);
    const handleSettings = (id) => {
        setSelectedUser(id);
    }
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/calendario');
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
        makeRequest.get('/user/getlista')
        .then(res=>setListaUtenti(res.data))
        .catch(err=>toast.error(err.response?.data));
    },[])
    useEffect(() => {
        if(handleNew) {
            makeRequest.get('/proprieta/getall')
            .then(res=> setListaProprieta(res.data));
        } else {
            setHandleNew(true);
        }
    },[listautenti])
    useEffect(() => {
        setHandleNew(false);
        const newArr = listautenti.map((item) => {
            let conto = 0;
            for(var i = 0; i < listaproprieta.length; i++) {
                if(item.ID === listaproprieta[i].RifProprietario) {
                    conto++;
                }
            }
            return {...item, Proprieta: conto};
        });
        setListaUtenti(newArr);
    },[listaproprieta])
    return(
        <Container className="dashboard" fluid>
            <Row>
                <Col xs={12}>
                    <MenuNav user={currentUser} active={{path: '/listauser'}}/>
                </Col>
                <Col xs={12} style={{textAlign: 'right'}}>
                    <h3 style={{marginRight: '2rem'}}>Lista utenti</h3>
                </Col>
                {selectedUser ? 
                        <SettingUser current={currentUser} user={selectedUser} close={handleSettings}/>
                    :
                <Col xs={11} className="wrapperList"> 
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Username</th>
                                <th>Admin</th>
                                <th>Proprietà</th>
                                <th>Gestisci</th>
                            </tr>
                        </thead>
                        <tbody>
                    {(() => {
                        const arr = [];
                        let conto = 0;
                        listautenti.map((item) => {
                            conto++;
                            return arr.push(<tr key={conto}><td>{conto}.</td><td><b>{item.username}</b></td><td><b>{item.Admin ? 'Si' : 'No'}</b></td><td><b>{item.Proprieta}</b></td><td><Gear onClick={() => handleSettings(item.ID)} className="settings"/></td></tr>);
                        })
                        return arr;
                    })()}
                        </tbody>
                    </Table>    
                </Col> 
            }
            </Row>
        </Container>
    )
}

export default Utenti;