import React, { useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { makeRequest } from '../Global/makeRequest';
import { useNavigate } from 'react-router-dom';

function Recovery() {
    const [id, setID] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const [emailsended, setSended] = useState(false);
    const [verified, setVerified] = useState(false);
    const navigate = useNavigate();
    const handlePassword = (event) => {
        event.preventDefault();
        setDisabled(true);
        if(password.length > 4) {
            makeRequest.put("/user/changepassword", {id, password})
            .then(res=> {
                toast.success("Password cambiata con successo");
                setTimeout(() => {
                    navigate('/');
                },1500);
            })
            .catch(err => {
                toast.error("Errore, riprova");
                setDisabled(false);
            })
        } else {
            toast.error("Inserisci almeno 5 caratteri");
            setDisabled(false);
        }
    }
    const handleCheckCode = (event) => {
        event.preventDefault();
        setDisabled(true);
        makeRequest.post('/user/checkRecovery', {id, code})
        .then(res=> {
            toast.success("Digita la tua nuova password")
            setVerified(true);
            setDisabled(false);
            setSended(false);
        })
        .catch(err=> {
            toast.error("Il codice inserito non è valido");
            setDisabled(false);
        })
    }
    const handleRecovery = (event) => {
        setDisabled(true);
        event.preventDefault();
        makeRequest.post('/user/getfromemail', {email})
        .then(res=> {
            const uid = res.data[0].ID;
            setID(uid);
            makeRequest.post('/email/sendemailrecovery', {email, uid})
            .then(res=> {
                toast.success('Ti è stato inviato un codice all\'email indicata');
                setSended(true);
                setDisabled(false);
            })
            .catch(err=> {
                toast.error("Si è verificato un errore durante l'invio dell'email, riprova.");
                setDisabled(false);
            })
        })
        .catch(err=> {
            toast.error("Non è stato trovato nessun account con questa email");
            setDisabled(false);
        });
    }
    return(
        <div className='bodycolor'>
        <Toaster/>
        <Container className="align-items-center justify-content-center d-flex login">
            <Row style={{width: '70%'}}>
                <Col xs={12} md={4}>
                    <Image
                        src='hostsicilia.svg'
                        fluid
                    />
                </Col>
                <Col className="insideLogin text-center"  xs={12} md={8}>
                    {!verified ? <>
                        {!emailsended ? 
                        <Form>
                            <FloatingLabel                            
                                label={<><img src="./person.svg"/><span> Email</span></>}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    disabled={isDisabled}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    className='mb-3'
                                />
                            </FloatingLabel>
                            <div className="d-grid gap-2">
                                <Button disabled={isDisabled} type="submit" size="lg" onClick={handleRecovery} className='loginbutton'>Invia</Button>
                            </div>
                        </Form>
                        :
                        <>
                        <Form>
                        <FloatingLabel label={<><img src="./lock.svg"/><span> Codice</span></>}>
                            <Form.Control
                                type="phone"
                                placeholder="Codice"
                                disabled={isDisabled}
                                onChange={(e) => {
                                    setCode(e.target.value)
                                }}
                                className='mb-3'
                            />
                        </FloatingLabel>
                        <div className="d-grid gap-2">
                            <Button disabled={isDisabled} type="submit" size="lg" onClick={handleCheckCode} className='loginbutton'>Invia</Button>
                        </div>
                        </Form>
                        </>
                        }
                    </>
                    :
                    <>
                    <Form>
                    <FloatingLabel label={<><img src="./lock.svg"/><span> Nuova password</span></>}>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            disabled={isDisabled}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            className='mb-3'
                        />
                    </FloatingLabel>
                    <div className="d-grid gap-2">
                        <Button disabled={isDisabled} type="submit" size="lg" onClick={handlePassword} className='loginbutton'>Invia</Button>
                    </div>
                    </Form>
                    </> 
                    }
                </Col>
            </Row>
        </Container>
    </div>
    );
}
export default Recovery;