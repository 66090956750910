import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Form, Button, FloatingLabel} from 'react-bootstrap';
import MenuNav from '../Global/MenuNav';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../Global/makeRequest';
import { AuthContext } from '../../Auth/authContext';
import { HandleLogout } from '../Global/Logout';
import { isvalidemail } from '../Global/Functions';
function Addnew() {
    const [currentUser, setCurrentUser] = useState(null);
    const {logout} = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [disabled, isDisabled] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            if(!res.data[0].Admin > 0)
                navigate('/');
            setCurrentUser(res.data[0])
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
    },[])
    const handleClick = () => {
        isDisabled(true);
        if(username.length > 3) {
            if(password.length > 3) {
                if(isvalidemail(email)) {
                    const admin = 0;
                    makeRequest.put('/user/insert',{username, password, email, admin})
                    .then(res=> {
                        toast.success('Account creato con successo')
                        setTimeout(()=>{
                            navigate('/listautenti')
                        },2000)
                    })
                    .catch(err=> {
                        console.log(err);
                        toast.error(err.response?.data);
                        isDisabled(false);
                    })
                } else {
                    toast.error("Inserisci una email valida");
                    isDisabled(false);
                }
            } else {
                toast.error("Inserisci una password valida: minimo 4 caratteri.");
                isDisabled(false);
            }
        } else {
            toast.error("Inserisci un username valido: minimo 4 caratteri.");
            isDisabled(false);
        }
    }
    return(
        <Container className="dashboard" fluid>
            <Toaster/>
            <Row>
                <Col xs={12}>
                    <MenuNav user={currentUser} active={{path: '/addnew'}}/>
                </Col>
                <Col xs={11} className="wrapperList">
                    <h1>Inserisci nuovo Account</h1>
                    <FloatingLabel label="Username">
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                            className="mb-3"
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Password">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            className="mb-3"
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Email">
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            className="mb-3"
                        />
                    </FloatingLabel>
                    <Button disabled={disabled} onClick={handleClick} variant="secondary">Crea Account</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default Addnew;