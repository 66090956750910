import React, {useContext} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import './App.css';
import Login from './Component/Login/Login';
import Dashboard from './Component/Dashboard/Dashboard';
import Addnew from './Component/Addnew/Addnew';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from './Auth/authContext';
import Proprieta from './Component/Proprieta/Proprieta';
import Contabilita from './Component/Contabilita/Contabilita';
import Addhouse from './Component/Addhouse/Addhouse';
import Calendario from './Component/Calendario/Calendario';
import Utenti from './Component/Utenti/Utenti';
import Recovery from './Component/Recovery/Recovery';
function App() {
  const { currentUser } = useContext(AuthContext);
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={currentUser === null ? <Navigate to="/login"/>:<Dashboard/>}></Route>
          <Route path="/login" element={currentUser !== null ? <Navigate to="/"/>:<Login/>}></Route>
          <Route path="/recovery" element={currentUser !== null ? <Navigate to="/"/>:<Recovery/>}></Route>
          <Route path="/calendario" element={currentUser === null ? <Navigate to="/login"/>:<Calendario/>}></Route>
          <Route path="/listaproprieta" element={currentUser === null ? <Navigate to="/login"/>:<Proprieta/>}></Route>
          <Route path="/listauser" element={currentUser === null ? <Navigate to="/login"/>:<Utenti/>}></Route> 
          <Route path="/addnew" element={currentUser === null ? <Navigate to="/login"/>:<Addnew/>}></Route>
          <Route path="/contabilita" element={currentUser === null ? <Navigate to="/login"/>:<Contabilita/>}></Route>
          <Route path="/addhouse" element={currentUser === null ? <Navigate to="/login"/>:<Addhouse/>}></Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;