import React, { useState, useEffect, useRef, useContext } from 'react';
import { Container, Row, Col, Image} from 'react-bootstrap';
import { Gear, CircleFill } from 'react-bootstrap-icons';
import { makeRequest } from '../Global/makeRequest';
import MenuNav from '../Global/MenuNav';
import SettingHome from './SettingHome';
import { AuthContext } from '../../Auth/authContext';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../Global/Logout';
function Proprieta() {
    const [currentUser, setCurrentUser] = useState(null)
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [listaproprieta, setListaProprieta] = useState([])
    const [listaprenotazioni, setListaPrenotazioni] = useState([]);
    const [selectedHome, setSelectedHome] = useState(0);
    const firstRender = useRef(true);
    const [updated, setUpdated] = useState(false);
    const handleClickSetting = (id) => {
        setSelectedHome(id);
    }
    const getIDfromProprieta = (data) => {
        const val = data.map((item) => {
            return item.ID;
        })
        if(val?.length === 1) {
            setSelectedHome(val[0]);
        }
        getListaPrenotazioni(val, data);
    }
    const getListaPrenotazioni = (proprieta, lista) => {
        makeRequest.post('/prenotazioni/getfromhouse', {proprieta})
        .then(res=> {
            setListaPrenotazioni(res.data);
            updateListaProprieta(res.data, lista);
        })
        .catch(err=> {
            updateListaProprieta([],lista)
        })
    }
    const updateListaProprieta = (data, lista) => {
        let newArr = lista.map((item) => {
            return {...item, Occupata: false}
        })
        newArr = newArr.map((item) => {
            for(var i = 0; i < data.length; i++) {
                if(item.ID === data[i].RifProprieta) {
                    return {...item, Occupata: true}
                }
            }
            return item;
        })
        setListaProprieta(newArr);
    }
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
    },[])
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if(selectedHome === 0)
            setUpdated(!updated);
    },[selectedHome])
    useEffect(() => {
        if(currentUser?.Admin > 0) {
            makeRequest.get('/proprieta/getall')
            .then(res => {
                setListaProprieta(res.data);
                getIDfromProprieta(res.data);
            })
        } else {
            const proprietari = currentUser?.ID;
            console.log(proprietari);
            makeRequest.post('/proprieta/get',{proprietari})
            .then(res => {
                setListaProprieta(res.data);
                getIDfromProprieta(res.data);
            })
        }
    },[currentUser, updated])
    return(
        <Container className="dashboard" fluid>
            <Row>
                <Col xs={12}>
                    <MenuNav user={currentUser} active={{path: '/listaproprieta'}}/>
                </Col>
                <Col xs={12} style={{textAlign: 'right'}}>
                    <h3 style={{marginRight: '2rem'}}>Tutte le proprietà</h3>
                </Col>
                <Container className="wrapperList"> 
                {selectedHome ? 
                        <SettingHome user={currentUser} home={selectedHome} close={handleClickSetting}/>
                    :
                    <Row>
                    {(() => {
                        const arr = [];
                        let conto = 0;
                        listaproprieta.map((item,index) => {
                            return arr.push(
                                <Col key={index} style={{padding: '10px', border: '0.2px solid gray', borderTop: 'none', borderRight: 'none', borderRadius: '5px'}} xs={12} md={4}>
                                    <div className="d-flex">
                                        <div>
                                            <p><Gear style={{margin: '0.2rem'}} onClick={() => handleClickSetting(item.ID)} className='settings'/></p>
                                            <p><CircleFill style={{margin: '1rem'}} className={item.Occupata ? 'greenlight' : 'redlight'}/></p>
                                        </div>
                                        <div className="d-flex">
                                            <Image
                                                src={item.Link}
                                                width="150"
                                                height="150"
                                            />
                                            <div style={{marginLeft: '0.5rem'}}>
                                                <h5 style={{verticalAlign: 'middle', marginTop: '2rem'}}>{item.Nome}</h5>
                                                <p>{item.Citta}</p>
                                                <p>{item.Via}</p>
                                                <p>Posti letto: {item.Posti}</p>
                                                {//item.Occupata ? <p>Check-Out: {item.DataOut</p>}
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                        return arr;
                    })()}  
                    </Row>
                }
                </Container> 
            </Row>
        </Container>
    )
}
export default Proprieta;