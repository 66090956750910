import React, { useState, useEffect, useContext } from 'react';
import {Container, Row, Col, Table, Form, FloatingLabel} from 'react-bootstrap';
import MenuNav from '../Global/MenuNav';
import { makeRequest } from '../Global/makeRequest';
import { AuthContext } from '../../Auth/authContext';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../Global/Logout';
import { Gear } from 'react-bootstrap-icons';
import SettingPren from '../Prenotazione/SettingPren';
import PieActiveArc from '../Global/Chart';
import { YMD, formattaDate, getDayOfWeek } from '../Global/Functions';
function Dashboard() {
    const [listaCheckIn, setListaCheckIn] = useState([]);
    const [listaPren, setListaPren] = useState([]);
    const [listaProprieta, setListaProprieta] = useState([]);
    const [listaprenotazioni, setListaPrenotazioni] = useState([]);
    const datainizio = formattaDate(getDayOfWeek(new Date(), 0))
    const datafine = formattaDate(getDayOfWeek(new Date(), 6))
    const [totaleguadagno, setTotaleGuadagno] = useState(0.0)
    const [dataguadagno, setDataGuadagno] = useState([{id:0, label:"Nessuno", value:0}]);
    const [currentUser, setCurrentUser] = useState(null);
    const { logout } = useContext(AuthContext);
    const [pren, setPren] = useState(0);
    const date = new Date();
    let datadomani = new Date(date.getTime())
    datadomani.setDate(datadomani.getDate() + 1)
    const formatDate = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate())+'-'+(date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1)+'-'+date.getFullYear();
    let dataoggi = formattaDate(date)
    datadomani = formattaDate(datadomani)
    const navigate = useNavigate();
    const updateNoteColazione = (id, note) => {
        listaprenotazioni.map((item) => {
            if(item.ID === id)
                return {...item, NoteColazione: note}
            return item;
        })
        makeRequest.put('/prenotazioni/colazione',{id,note})
    }
    const getGuadagnoProprieta = (data) => {
        const proprieta = data.map((item) => {
            return item.ID;
        })
        /*const guadagno = data.map((item) => {
            return item.Guadagno;
        })
        makeRequest.post('/spese/getfromproprieta',{proprieta})
        .then(res=>{
            setGuadagno(data, res.data, pren);
        })
        .catch(err=>console.log(err))*/
        makeRequest.post('/saldo/get',{proprieta})
        .then(res=>{
            const saldi = res.data;
            makeRequest.get('/guadagni/getall')
            .then(res=> {
                const guadagni = res.data;
                setGuadagno(data, saldi, guadagni)
            })
        })
    } 
    const setGuadagno = (proprieta, saldi, guadagni) => {
        //console.log(proprieta, saldi, guadagni);
        const arrFinale = []
        let totale = 0.0;
        for(var i = 0; i < proprieta.length; i++) {
            let var1 = {id: proprieta[i].ID, label: proprieta[i].Nome, value: 0.0}
            saldi.map((item) => {
                if(item.RifProprieta === proprieta[i].ID) {
                    if(datainizio <= item.Data && item.Data <= datafine) {
                        //console.log(datainizio, item.Data, datafine, item);
                        var1.value = parseFloat(var1.value.toFixed(2))+parseFloat(item.Quantita.toFixed(2));
                        totale = parseFloat(totale.toFixed(2))+parseFloat(item.Quantita.toFixed(2));
                    }
                }
            })
            if(var1.value > 0.0)
                arrFinale.push(var1);
        }
        guadagni.map((item) => {
            let var1 = {id: item.ID+1000, label: item.Riferimento, value: 0.0}
            if(datainizio <= item.DataInserimento && item.DataInserimento <= datafine) {
                if(item.Pagato === 1) {
                    var1.value = parseFloat(var1.value.toFixed(2))+parseFloat(item.Costo.toFixed(2));
                    totale = parseFloat(totale.toFixed(2))+parseFloat(item.Costo.toFixed(2));    
                }      
            } 
            if(var1.value > 0.0)
                arrFinale.push(var1);     
        })
        setDataGuadagno(arrFinale)
        setTotaleGuadagno(totale);
    }

    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/calendario');
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
        makeRequest.get('/prenotazioni/gettoday')
        .then(res=> {
            setListaCheckIn(res.data);
        })
        .catch(async err=> {
            if(err.response?.status === 403) {
                HandleLogout(logout, navigate)
            } else {
                console.log(err);
            }
        });
        makeRequest.get('/proprieta/getall')
        .then(res=> {
            const data = res.data;
            makeRequest.post('/prenotazioni/getall')
            .then(res=>{
                setListaProprieta(data);
                getGuadagnoProprieta(data);       
                setListaPrenotazioni(res.data);         
            })
            .catch(err=>console.log(err))
        })
        .catch(err=>console.log(err))
        makeRequest.get('/prenotazioni/getfive')
        .then(res=> {
            setListaPren(res.data)
        })
    },[])
    const handleSettings = (id) => {
        setPren(id);
    }
    const getNomeCasa = (id) => {
        let val = 'Nessuno';
        listaProprieta.map((item) => {
            if(item.ID === id) 
                val = item.Nome;
            return false;
        })
        return val;
    }
    return(
        <Container className="dashboard" fluid>
            <Row>
                <Col xs={12}>
                    <MenuNav user={currentUser} active={{path: '/'}}/>
                </Col>
                {pren ? <SettingPren usern={currentUser} pren={pren} close={setPren}/> : <>
                <Col xs={12} style={{textAlign: 'right'}}>
                    <h3 style={{marginRight: '2rem'}}>Benvenuto, {currentUser?.username}</h3>
                </Col>
                {(() => {
                    const arr = [];
                    listaprenotazioni.map((item) => {
                        if(item.DataIn === dataoggi || item.DataIn === datadomani || item.DataIn <= dataoggi && item.DataOut >= dataoggi) {
                            if(item.Colazione) {
                                return arr.push(<Col xs={11} className="wrapperList">
                                    <h3>{getNomeCasa(item.RifProprieta)} - {item.Nome} {item.Cognome} - {YMD(item.DataIn)} / {YMD(item.DataOut)} - Adulti: {item.Adulti} - Bambini: {item.Bambini} - Neonati: {item.Neonati}</h3>
                                    <FloatingLabel label="Note colazione">
                                        <Form.Control as="textarea"
                                            defaultValue={item.NoteColazione}
                                            rows={4}
                                            placeholder="Note colazione"
                                            onChange={(e) => updateNoteColazione(item.ID, e.target.value)}
                                        />
                                    </FloatingLabel>
                                </Col>)
                            }
                        }
                        return item;
                    })
                    return arr;
                })()}
                {listaCheckIn.length ? 
                <Col xs={11} className="wrapperList"> 
                    <h6>Check-In Giornalieri - <span style={{color: 'rgb(10, 10, 200)'}}><b>{formatDate}</b></span></h6>  
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Proprietà</th>
                                <th>Orario di ingresso</th>
                                <th>Adulti</th>
                                <th>Bambini</th>
                                <th>Neonati</th>
                                <th>Gestisci</th>
                            </tr>
                        </thead>
                        <tbody>
                    {(() => {
                        const arr = [];
                        let conto = 0;
                        listaCheckIn.map((item) => {
                            for(var i = 0; i < listaProprieta.length; i++) {
                                if(item.RifProprieta === listaProprieta[i].ID) {
                                    conto++;
                                    arr.push(<tr key={conto}><td>{conto}.</td><td><b>{listaProprieta[i].Nome}</b></td><td><b>{item.OraIn}</b></td><td><b>{item.Adulti}</b></td><td><b>{item.Bambini}</b></td><td><b>{item.Neonati}</b></td><td><Gear onClick={() => handleSettings(item.ID)} className="settings"/></td></tr>);
                                }
                            }
                            return item;
                        })
                        return arr;
                    })()}
                    </tbody>
                    </Table>    
                </Col> : null }
                <Col xs={11} className="wrapperList">
                    <h6>Entrate settimanali: <b>{totaleguadagno}€</b></h6>
                    {PieActiveArc(dataguadagno)}
                </Col>
                <Col xs={11} className="wrapperList">
                    <h6>Ultime prenotazioni inserite</h6>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Data inserimento prenotazione</th>
                                <th>Casa</th>
                                <th>Date alloggio</th>
                                <th>Gestisci</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                const arr = []
                                let conto = 0;
                                listaPren.map((item) => {
                                    for(var i = 0; i < listaProprieta.length; i++) {
                                        if(item.RifProprieta === listaProprieta[i].ID) {
                                            conto++;
                                            return arr.push(<tr>
                                                <td>{conto}</td>
                                                <td>{item.DataInserimentoS}</td>
                                                <td>{listaProprieta[i].Nome}</td>
                                                <td>{item.DataIn} - {item.DataOut}</td>
                                                <td><Gear onClick={() => handleSettings(item.ID)} className="settings"/></td>
                                            </tr>)
                                        }
                                    }
                                    return item;
                                })
                                return arr;
                            })()}
                        </tbody>
                    </Table>
                </Col>
                </>
                }
            </Row>
        </Container>
    )
}

export default Dashboard;