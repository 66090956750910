import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Form, Button, FloatingLabel} from 'react-bootstrap';
import MenuNav from '../Global/MenuNav';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../Global/makeRequest';
import { AuthContext } from '../../Auth/authContext';
import { HandleLogout } from '../Global/Logout';
function Addhouse() {
    const [nominativo, setNominativo] = useState('');
    const [citta, setCitta] = useState('');
    const [via, setVia] = useState('');
    const [posti, setPosti] = useState(0);
    const [disabled, isDisabled] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const {logout} = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            if(!res.data[0].Admin > 0) {
                navigate('/');
            }
            setCurrentUser(res.data[0])
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
    },[])
    const handleChangePosti = (e) => {
        const val = e.target.value;
        console.log(val);
        if(isNaN(val) || val < 0)
            setPosti(0);
        else 
            setPosti(val);
    }
    const handleClick = () => {
        isDisabled(true);
        if(nominativo.length > 4 && nominativo.length < 200) {
            if(via.length > 3) {
                if(citta.length > 3 && citta.length < 50) {
                    if(!isNaN(posti) && posti > 0) {
                        const proprietario = 0;
                        makeRequest.put('/proprieta/insert',{nominativo, citta, via, posti, proprietario})
                        .then(res=> {
                            toast.success('Proprietà inserita con successo')
                            setTimeout(()=>{
                                navigate('/listaproprieta')
                            },2000)
                        })
                        .catch(err=> {
                            console.log(err);
                            toast.error(err.response?.data);
                            isDisabled(false);
                        })
                    } else {
                        toast.error("Inserisci il numero di posti letto (1+)");
                        isDisabled(false);
                    }
                } else {
                    toast.error("Inserisci la città della Proprietà (3-50 caratteri)");
                }
            } else {
                toast.error("Inserisci una via valida (minimo 3 caratteri)");
                isDisabled(false);
            }
        } else {
            toast.error("Inserisci un nominativo valido (5-199 caratteri)");
            isDisabled(false);
        }
    }
    return(
        <Container className="dashboard" fluid>
            <Toaster/>
            <Row>
                <Col xs={12}>
                    <MenuNav user={currentUser} active={{path: '/addhouse'}}/>
                </Col>
                <Col xs={11} className="wrapperList">
                    <h1>Inserisci nuova Proprieta</h1>
                    <FloatingLabel label="Nominativo casa">
                        <Form.Control
                            type="text"
                            placeholder="Nominativo casa"
                            onChange={(e) => setNominativo(e.target.value)}
                            className="mb-3"
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Via">
                        <Form.Control
                            type="text"
                            placeholder="Via"
                            onChange={(e) => setVia(e.target.value)}
                            className="mb-3"
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Città">
                        <Form.Control
                            type="text"
                            placeholder="Città"
                            onChange={(e) => setCitta(e.target.value)}
                            className="mb-3"
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Posti letto">
                        <Form.Control
                            type="number"
                            placeholder="Posti letto"
                            value={posti}
                            onChange={(e) =>  handleChangePosti(e)}
                            className="mb-3"
                        />
                    </FloatingLabel>
                    <Button disabled={disabled} onClick={handleClick} variant="secondary">Crea Proprietà</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default Addhouse;