import {React, useContext, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { App, PersonPlus, BoxArrowRight, Houses, Calendar2Week, CashCoin, HouseAdd, House, People} from 'react-bootstrap-icons';
import Button from 'react-bootstrap/esm/Button';
import Find from './Find';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from './makeRequest';
import { AuthContext } from '../../Auth/authContext';
import { Image } from 'react-bootstrap';
function MenuNav({user, active, showProperty}) {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const handleLogout = async() => {
    try {
      await logout()
      navigate('/login');
    }
    catch(err) {
      console.log(err);
    }
  }
  const [findProperty, setFindProperty] = useState([
  ])
  const [showFind, setShowFind] = useState(false);
  const [tab, setTab] = useState(-1);
  const handleKey = event => {
      switch(event.key) {
          case 'ArrowUp': {
            if(findProperty?.length && tab >= 0)
              setTab(tab-1);
            break;
          }
          case 'ArrowDown': {
            if(findProperty?.length - 1 > tab)
              setTab(tab+1);
              break;
          }
          case 'Enter': {
            let id = undefined;
            findProperty.map((item, index) => {
              if(index === tab)
                id = item.ID; 
              return item;
            })
            showProperty({id:id});
            break;
          }
          default: {
            setTab(-1)
            break;
          }
      }
  };
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const handleTrue = () => {
    setShow(true);
  }
  const handleFalse = () => {
    setFindProperty(null);
    setTab(-1);
    setShow(false);
  }
  const handleChange = (event) => {
    setSearch(prev => event.target.value);
    let value=null;
    if(event.target.value?.length) {
      value = event.target.value;
      makeRequest.post("/find/getproperty/",{value})
      .then((res) => setFindProperty(res.data))
      .catch((err) => setFindProperty([]))
      setShowFind(true);
    } else {
      setFindProperty(null);
      setShowFind(false);
    }
  }
  return (
    <>
    <Navbar variant="light" key='md' expand='md' style={{background: 'inherit'}}>
      <Container fluid>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
            <Navbar.Offcanvas
              style={{background: '#000000'}}
              id={`offcanvasNavbar-expand-md}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="end"
              onShow={handleTrue}
              onHide={handleFalse}
            >
            <Offcanvas.Header style={{border: '1px solid black', borderRadius: '5px', background: 'white'}} closeButton>
              <Offcanvas.Title style={{color: '#FFFFFF'}} id={`offcanvasNavbarLabel-expand-md`}>
                <Image
                src="./hostsicilia.svg"
                width="150"
                rounded
                fluid
                alt="Host Sicilia Palermo"
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-start flex-grow-1 pe-3">
                <FloatingLabel
                  label={<><img src="./search.svg"/><span> Ricerca proprietà</span></>}
                >
                  <Form.Control
                    onFocus={() => setShowFind(true)}
                    onBlur={() => setTimeout(() => {setShowFind(false)},200)}
                    onKeyDown={handleKey}
                    key="find"
                    type="text"
                    id="find"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                             //   onChange={(e) => {setUser(e.target.value)}}
                  />
                </FloatingLabel>
                {show && showFind ? <Find tab={tab} rows={findProperty} show={showProperty} /> : null}
              </Nav>
              <Nav activeKey={active.path} className="justify-content-center flex-grow-1 pe-3">
              {user?.Admin > 0 ?<Nav.Link className={(show ? "nav-mobile" : '')} href="/">{(show ? <span><App /> Dashboard</span> : <App/>)}</Nav.Link>: null}
                {user?.Admin > 0 ? <Nav.Link className={(show ? "nav-mobile" : '')} href="/addnew">{(show ? <span><PersonPlus/> Inserisci utente</span> : <PersonPlus/>)}</Nav.Link> : null}
                {user?.Admin > 0 ? <Nav.Link className={(show ? "nav-mobile" : '')} href="/addhouse">{(show ? <span><HouseAdd/> Inserisci proprietà</span> : <HouseAdd/>)}</Nav.Link> : null}
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/calendario">{(show ? <span><Calendar2Week/> Calendario</span> : <Calendar2Week/>)}</Nav.Link>
                {user?.Admin > 0 ? <Nav.Link className={(show ? "nav-mobile" : '')} href="/listauser">{(show ? <span><People/> Utenti</span> : <People/>)}</Nav.Link> : null}
                <Nav.Link className={(show ? "nav-mobile" : '')} href="/listaproprieta">{(show ? <span><Houses/> Proprietà</span> : <Houses/>)}</Nav.Link>
                {user?.Admin > 0 ? <Nav.Link className={(show ? "nav-mobile" : '')} href="/contabilita">{(show ? <span><CashCoin/> Contabilità</span> : <CashCoin/>)}</Nav.Link> : null}
              </Nav>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Button onClick = {handleLogout} variant = "light">Logout <BoxArrowRight/></Button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {showFind ? <Find tab={tab} rows={findProperty} show={showProperty}/> : null }
      </>
  );
}

export default MenuNav;