import React, { useContext, useEffect, useRef, useState } from 'react';
import MenuNav from '../Global/MenuNav';
import { Container, Row, Col, Table, Button, Modal, Form, FloatingLabel} from 'react-bootstrap';
import { makeRequest } from '../Global/makeRequest';
import toast, { Toaster } from 'react-hot-toast';
import { ArrowLeft, ArrowRight, CalendarPlus } from 'react-bootstrap-icons';
import SettingPren from '../Prenotazione/SettingPren';
import { AuthContext } from '../../Auth/authContext';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../Global/Logout';
import { validateHhMm } from '../Global/Functions';
function Calendario() {
    const { logout } = useContext(AuthContext);
    const palette = ['rgb(0, 89, 178)','rgb(46, 150, 255)','rgb(255, 194, 76)','rgb(255, 159, 14)','rgb(243, 130, 0)','rgb(42, 191, 222)','rgb(31, 148, 173)','rgb(189, 44, 56)','rgb(255, 49, 67)','rgb(255, 130, 130)']
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const firstRender = useRef(true);
    const [modalPren, showModalPren] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const arrayDate = (datain, dataout) => {
        const start = new Date(datain);
        const end = new Date(dataout);
        const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
        const dates = [];
        for (let i = 0; i < days; i++) {
            const currentDate = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
            dates.push(formatDate(currentDate));
        }
        return dates;
    }
    function compareTime(time1, time2) {
        return new Date(time1) > new Date(time2); // true if time1 is later
    }
    const [modifypren, setModifyPren] = useState({
        Nome: '', Cognome: '', Nazionalita: '', Cellulare: '', DataIn: '', OraIn: '', DataOut: '', OraOut: '', Adulti:0, Bambini:0, Neonati:0, Culla:0, Seggiolone:0, Bagnetto:0, Prezzo:0.0, Colazione:false, Note:'',RifProprieta:0, OTA: 'Privato', PercentualeOTA: 0.0, Cedolare: 0, PercentualeCedolare: 21
    });
    const cleanModifyPren =  () => {
        setModifyPren({Nome: '', Cognome: '', Nazionalita: '', Cellulare: '', DataIn: '', OraIn: '', DataOut: '', OraOut: '', Adulti:0, Bambini:0, Neonati:0, Culla:0, Seggiolone:0, Bagnetto:0, Prezzo:0.0, Colazione:false, Note:'',RifProprieta:0, OTA: 'Privato', PercentualeOTA: 0.0, Cedolare: 0, PercentualeCedolare: 21});
    }
    const checkProprietario = (id, proprieta) => {
        let val = false;
        listaproprieta.map((item) => {
            if(item.ID === proprieta) {
                if(item.RifProprietario === id) {
                    val = true
                }
            }
            return item;
        })
        return val;
    }
    const handleSavePren = () => {
        setDisabled(true);
        const nome = modifypren.Nome;
        const cognome = modifypren.Cognome;
        const nazionalita = modifypren.Nazionalita;
        const datain = modifypren.DataIn;
        const orain = modifypren.OraIn;
        const dataout = modifypren.DataOut;
        const oraout = modifypren.OraOut;
        const adulti = modifypren.Adulti;
        const bambini = modifypren.Bambini;
        const neonati = modifypren.Neonati; 
        if(parseInt(adulti)+parseInt(bambini)+parseInt(neonati) === 0) {
            toast.error("Inserisci almeno una persona nella prenotazione")
            return setDisabled(false);
        }
        const culla = modifypren.Culla;
        const seggiolone = modifypren.Seggiolone;
        const bagnetto = modifypren.Bagnetto;
        const note = modifypren.Note;
        const cellulare = modifypren.Cellulare;
        const proprieta = parseInt(modifypren.RifProprieta);
        const ota = modifypren.OTA;
        let percentualeota = String(modifypren.PercentualeOTA);
        const cedolare = modifypren.Cedolare;
        const percentualecedolare = modifypren.PercentualeCedolare;
        let prezzo = String(modifypren.Prezzo);
        if(prezzo.indexOf(',') > -1) {
            prezzo = parseFloat(prezzo.replace(',','.')).toFixed(2)
        } else
            prezzo = parseFloat(prezzo.replace(',','.')).toFixed(2)
        if(percentualeota.indexOf(',') > -1) {
            percentualeota = parseFloat(percentualeota.replace(',','.')).toFixed(2)
        } else
            percentualeota = parseFloat(percentualeota.replace(',','.')).toFixed(2)
        const colazione = modifypren.Colazione;
        if(proprieta > 0) {
            if(currentUser?.Admin > 0 || checkProprietario(currentUser?.ID, proprieta) === true) {
                if(nome.length > 2) {
                    if(cognome.length > 2) {
                        if(nazionalita.length > 2) {
                            if(datain.length === 10) {
                                if(orain.length === 0 || validateHhMm(orain)) {
                                    if(dataout.length === 10) {
                                        if(compareTime(datain,dataout)) {
                                            toast.error("Inserisci una data di Check In antecedente alla data di Check Out");
                                            return setDisabled(false);
                                        }
                                        if(oraout.length === 0 || validateHhMm(oraout)) {
                                            if(cellulare.length > 8) {
                                                if(prezzo >= 0) {
                                                    const arraydate = arrayDate(datain, dataout);
                                                    const id = 0;
                                                    makeRequest.put('/prenotazioni/insert',{arraydate, nome, cognome, nazionalita, datain, orain, dataout, oraout, adulti, bambini, neonati, culla, seggiolone, bagnetto, cellulare, note, prezzo, colazione, ota, percentualeota, cedolare, percentualecedolare, proprieta, id})
                                                    .then(res=> {
                                                        toast.success("Prenotazione inserita con successo");
                                                        showModalPren(false);
                                                        //cleanModifyPren();
                                                        //console.log(res.data.insertId);
                                                        setShowPren(res.data.insertId)
                                                    })
                                                    .catch(err=> {
                                                        toast.error(err.response?.data);
                                                    })
                                                } else {
                                                    toast.error("Inserisci un prezzo valido");
                                                }
                                            } else {
                                                toast.error("Inserisci un numero di cellulare valido");
                                            }
                                        } else {
                                            toast.error("Inserisci un orario di CheckOut valido");
                                        }
                                    } else {
                                        toast.error("Inserisci una data di CheckOut valida");
                                    }
                                } else {
                                    toast.error("Inserisci un orario di CheckIn valido");
                                }
                            } else {
                                toast.error("Inserisci una data di CheckIn valida");
                            }
                        } else {
                            toast.error("Inserisci una Nazionalità valida ( min. 3 caratteri )");
                        }
                    } else {
                        toast.error("Inserisci un Cognome valido ( min. 3 caratteri )");
                    }
                } else {
                    toast.error("Inserisci un Nome valido ( min. 3 caratteri )");
                }
            } else {
                toast.error("Si è verificato un errore, riprova più tardi");
            }
        } else {
            toast.error("Seleziona una proprietà");
        }
        setDisabled(false);
    }
    const handleNumber = (e, tipo) => {
        let value = e.target.value;
        if(value < 0) {
            value = 0;
        }
        switch(tipo) {
            case "Adulti": {
                setModifyPren({...modifypren, Adulti: value});
                break;
            }
            case "Bambini": {
                setModifyPren({...modifypren, Bambini: value});
                break;
            }
            case "Neonati": {
                setModifyPren({...modifypren, Neonati: value});
                break;
            }
            case "Seggiolone": {
                setModifyPren({...modifypren, Seggiolone: value});
                break;           
            }
            case "Culla": {
                setModifyPren({...modifypren, Culla: value});
                break;           
            }
            case "Bagnetto": {
                setModifyPren({...modifypren, Bagnetto: value});
                break;           
            }
            case "Prezzo": {
                setModifyPren({...modifypren, Prezzo: value});
                break;     
            }
            case "Percentuale": {
                setModifyPren({...modifypren, PercentualeOTA: value});
                break;          
            }
        }
    }
    const checkinOut = (proprieta, data) => {
        let diff = 1;
        const altradata = listaprenotazioni.filter(item => item.RifProprieta === proprieta && item.DataIn === data)
        //console.log(altradata);
        if(altradata !== false) {
            const dataIn = new Date(data);
            const dataOut = new Date(altradata[0]?.DataOut);
            const datafinale = new Date(datafine);
            diff = dateDiffInDays(dataIn, dataOut);
            const otherdiff = dateDiffInDays(dataOut, datafinale)
            const othersdiff = dateDiffInDays(dataIn, datafinale);
            if(otherdiff < -1 ) {
                const index = arrgiorni.indexOf(formatDate(dataIn));
                if(index !== 6) 
                    diff = othersdiff+1;
                else
                    diff = 1;
            }
        }
        return diff;

    }
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${year}-${month}-${day}`;
    };
    const getDayOfWeek = (d, dayweek) => {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -7+dayweek : dayweek); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
    const handleClickPren = (e) => {
        const id = e.currentTarget?.id;
        setShowPren(id);
    }
    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      }
    const YMD = (date) => {
        const val = date.split('-');
        return val[2]+'-'+val[1]+'-'+val[0];
    }
    const handleBack = () => {
        const first = new Date(datainizio);
        const second = new Date(datafine);
        first.setDate(first.getDate() - 1);
        second.setDate(second.getDate() - 1);
        setStartDate(formatDate(first));
        setEndDate(formatDate(second));

    }
    const handleForward = () => {
        const first = new Date(datainizio);
        const second = new Date(datafine);
        first.setDate(first.getDate() + 1);
        second.setDate(second.getDate() + 1);
        setStartDate(formatDate(first));
        setEndDate(formatDate(second));
    }
    let trovatello;
    const datetoday = new Date();
    const dayweek = datetoday.getDay();
    const todayDate = formatDate(datetoday);
    const [datainizio, setStartDate] = useState(formatDate(getDayOfWeek(new Date(), dayweek)));
    const [datafine, setEndDate] = useState(formatDate(getDayOfWeek(new Date(), 6+dayweek)));
    const [arrgiorni, setDateArray] = useState([]);
    const [listaprenotazioni, setListaPrenotazioni] = useState([])
    const [listaproprieta, setListaProprieta] = useState([]);
    const [showpren, setShowPren] = useState(0);
    const handleStartDate = (e) => {
        const val = e.currentTarget.value;
        //console.log(val)
        const dateselect = new Date(val);
        const weekday = dateselect.getDay();
        setStartDate(formatDate(getDayOfWeek(dateselect, weekday)));
        setEndDate(formatDate(getDayOfWeek(dateselect, 6+weekday)));
    }
    const getDates = () => {
        if (datainizio && datafine) {
            const start = new Date(datainizio);
            const end = new Date(datafine);
            const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
            const dates = [];
            for (let i = 0; i <= days; i++) {
                const currentDate = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
                dates.push(formatDate(currentDate));
            }
            setDateArray(dates);
        }
    };
    useEffect(() => {
        makeRequest.post('/prenotazioni/getall',{datainizio})
        .then(res=>{
            setListaPrenotazioni(res.data)
        })
        .catch(err=> {
            if(err.response?.status === 400) {
                toast.dismiss(trovatello);
                trovatello = toast.error('Non sono state trovate prenotazioni in questa data');
            }
        })
        getDates();
    },[datainizio, datafine, showpren])
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
    },[])
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if(currentUser?.Admin) {
            makeRequest.get('/proprieta/getall')
            .then(res=> {
                setListaProprieta(res.data);
            })
        } else {
            const proprietari = currentUser.ID;
            makeRequest.post('/proprieta/get',{proprietari})
            .then(res=>{
                setListaProprieta(res.data);   
            })
        }
    },[currentUser])
    return (
        <>
        <Container className="dashboard" fluid>
            <Toaster/>
            <Row>
                <Col xs={12}>
                    <MenuNav user={currentUser} active={{path: '/calendario'}}/>
                </Col>
                {showpren ? <>
                    <SettingPren usern={currentUser} pren={showpren} close={setShowPren} />
                </>
                :
                <>
                <Col xs={12} style={{textAlign: 'left'}}>
                    <Button variant="success" style={{margin: '0.5rem'}} onClick={() => showModalPren(true)}><CalendarPlus style={{fontSize: '22px'}}/> Prenota</Button>
                </Col>
                <Col xs={6} style={{textAlign: 'left'}}>
                    <FloatingLabel label="Data">
                        <Form.Control
                            type="date"
                            value={datainizio}
                            placeholder="Data"
                            onChange={handleStartDate}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={6} style={{textAlign: 'right'}}>
                    <h3 style={{marginRight: '2rem'}}>Calendario</h3>
                    <Button style={{margin: '0.5rem', marginRight: '2rem'}} onClick={handleBack}><ArrowLeft/></Button>
                    <Button style={{margin: '0.5rem', marginRight: '2rem'}} onClick={handleForward}><ArrowRight/></Button>
                </Col>
                <Col xs={11} className="wrapperList">
                    <Table responsive bordered hover striped>
                        <thead>
                            <tr className="position-sticky" style={{textAlign:'center'}} key="lista">
                                <th className='position-sticky'>Proprietà</th>
                                {(() => {
                                    const arr = [];
                                    arrgiorni.map((item)=> {
                                        return arr.push(<th className='position-sticky' scope='col'>{YMD(item)}</th>);
                                    })
                                    return arr;
                                })()}
                            </tr>
                        </thead>
                        <tbody>
                        {(() => {
                            const arr = [];
                            let change = 0;
                            listaproprieta.map((item, index) => {
                                //console.log('-------------------------------');
                                if(change >= palette.length)
                                    change = 0;
                                let idcolor = palette[change];
                                change++;
                                let arr2 = [];
                                arr2.push(<td style={{color: idcolor, fontWeight: 'bold'}}>{item.Nome}</td>)
                                let span = 0;
                                const arr3 = [];
                                let trovato = false;
                                let count = 0;
                                let countprima = 0;
                                let idprima = 0;
                                let prenprima = 0;
                                for(var i = 0; i < arrgiorni.length; i++) {
                                    if(span)
                                        span--;
                                    /*if(!span)
                                        trovato = false;*/
                                    for(var b = 0; b < listaprenotazioni.length; b++) {
                                        if(item.ID === listaprenotazioni[b].RifProprieta) {
                                            if((listaprenotazioni[b].DataIn <= datainizio || datafine >= listaprenotazioni[b].DataIn) || (datainizio >= listaprenotazioni[b].DataOut || datafine >= listaprenotazioni[b].DataOut)) {
                                                console.log('RifProprieta:',item.Nome,item.ID, 'ID:', listaprenotazioni[b].ID, 'DATAINIZIO:',datainizio, 'DATAFINE',datafine, 'DataIn', listaprenotazioni[b].DataIn, 'DataOut', listaprenotazioni[b].DataOut, 'Arrgiorni', arrgiorni[i], 'span', span, 'count', count, 'countprima', countprima)
                                                let dataIn = new Date(listaprenotazioni[b].DataIn);
                                                let dataOut = new Date(listaprenotazioni[b].DataOut);
                                                let diff = dateDiffInDays(dataIn, dataOut);
                                                if(datainizio === listaprenotazioni[b].DataOut && arrgiorni[i] === listaprenotazioni[b].DataOut) {
                                                    countprima = count;
                                                    prenprima++;
                                                    //console.log('entro qui CHECKOUT DAY SING', listaprenotazioni[b].DataIn, listaprenotazioni[b].DataOut, item.Nome, arrgiorni[i])
                                                    //SOLO GIORNO CHECKOUT SINGOLO ( PRIMO GIORNO DELLA SETTIMANA)
                                                    span = 1;
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut)))
                                                        prenprima--;
                                                    arr3.push({code: <div style={{backgroundColor: idcolor}} id={listaprenotazioni[b].ID} onClick={(e) => handleClickPren(e)} className='left d-grid'>
                                                        <span>Prenotazione #{listaprenotazioni[b].ID}</span>
                                                        <span>Check-Out: <br/><b style={{color: 'red', backgroundColor: idcolor, fontWeight: 'bold', textShadow: '1px 1px #010101'}}>{YMD(listaprenotazioni[b].DataOut)}</b> - {listaprenotazioni[b].OraOut}</span>
                                                    </div>,distance: 1, count: count, index: i, prenprima: prenprima});
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut))) {
                                                        count++;
                                                        idprima = listaprenotazioni[b].ID;
                                                        //span = 0;
                                                    }
                                                    trovato = true;
                                                } else if(arrgiorni[i] === listaprenotazioni[b].DataIn && datafine >= listaprenotazioni[b].DataOut && listaprenotazioni[b].DataOut >= arrgiorni[i] && (!span || countprima !== count)) {
                                                    //console.log('entro qui PREN DENTRO SETT', listaprenotazioni[b].DataIn, listaprenotazioni[b].DataOut, item.Nome, arrgiorni[i], diff+1)
                                                    //ENTRO QUI SE LA DURATA DELLA PREN FINISCE DENTRO LA SETTIMANA CORRENTE)
                                                    if(diff > 0)
                                                        span = diff+1;
                                                    countprima = count;
                                                    prenprima++;
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut)))
                                                        prenprima--;
                                                    arr3.push({code:
                                                        <div id={listaprenotazioni[b].ID} onClick={(e) => handleClickPren(e)} className='d-flex' style={{margin: '0 auto'}}>
                                                            <p className='occupiednoborder d-grid' style={{backgroundColor: idcolor, height:'100%', width:'100%', textAlign: 'left'}}>
                                                                <span>Prenotazione #{listaprenotazioni[b].ID}</span>
                                                                <span>Check-In: <br/><b className={i === 0 ? 'checkin' : ''}>{YMD(listaprenotazioni[b].DataIn)}</b> - {listaprenotazioni[b].OraIn}</span>
                                                            </p>
                                                            <p className='leftnoborder d-grid' style={{backgroundColor: idcolor, height:'100%', width: '100%', textAlign: 'right'}}>
                                                                <br/>
                                                                <span>Check-Out: <br/><b>{YMD(listaprenotazioni[b].DataOut)}</b> - {listaprenotazioni[b].OraOut}</span>
                                                            </p>
                                                        </div>, distance: span, count: count, index: i, prenprima: prenprima})
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut))) {
                                                        count++;
                                                        idprima = listaprenotazioni[b].ID;
                                                    }
                                                    trovato = true;
                                                } else if(listaprenotazioni[b].DataIn < datainizio && datafine >= listaprenotazioni[b].DataOut && arrgiorni[i] <= listaprenotazioni[b].DataOut && (!span || countprima !== count && idprima !== listaprenotazioni[b].ID)) {
                                                    //ENTRO QUI SE LA PREN INIZIA PRIMA E LA DURATA DELLA PREN FINISCE DENTRO LA SETTIMANA CORRENTE)
                                                    //console.log(' SONO ENTRATO entro qui PREN INZIA PRIMA E FINISCE SETT', listaprenotazioni[b].DataIn, listaprenotazioni[b].DataOut, item.Nome, arrgiorni[i])
                                                    dataIn = new Date(arrgiorni[i]);
                                                    diff = dateDiffInDays(dataIn, dataOut);
                                                    if(diff > 0)
                                                        span = diff+1;
                                                    countprima = count;
                                                    prenprima++;
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut)))
                                                        prenprima--;
                                                    arr3.push({code:
                                                        <div id={listaprenotazioni[b].ID} onClick={(e) => handleClickPren(e)} className='d-flex' style={{margin: '0 auto'}}>
                                                            <p className='occupiednoborder d-grid' style={{backgroundColor: idcolor, height:'100%', width:'100%', textAlign: 'left'}}>
                                                                <span>Prenotazione #{listaprenotazioni[b].ID}</span>
                                                                <span>Check-In: <br/><b>{YMD(listaprenotazioni[b].DataIn)}</b> - {listaprenotazioni[b].OraOut}</span>
                                                            </p>
                                                            <p className='leftnoborder d-grid' style={{backgroundColor: idcolor, height:'100%', width: '100%', textAlign: 'right'}}>
                                                                <br/>
                                                                <span>Check-Out: <br/><b>{YMD(listaprenotazioni[b].DataOut)}</b> - {listaprenotazioni[b].OraOut}</span>
                                                            </p>
                                                        </div>, distance: span, count: count, index: i, prenprima: prenprima})
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut))) {
                                                        count++;
                                                        idprima = listaprenotazioni[b].ID;
                                                    }
                                                    trovato = true;
                                                } else if(listaprenotazioni[b].DataIn < datainizio && datafine < listaprenotazioni[b].DataOut && (!span || countprima !== count && idprima !== listaprenotazioni[b].ID)) {
                                                    //console.log('entro qui PREN INZIA PRIMA E FINISCE DOPO SETT', listaprenotazioni[b].DataIn, listaprenotazioni[b].DataOut, item.Nome, arrgiorni[i])
                                                    //ENTRO QUI SE LA PREN INIZIA PRIMA E LA DURATA DELLA PREN FINISCE DOPO LA SETTIMANA CORRENTE)
                                                    if(diff > 0)
                                                        span = diff+1;
                                                    countprima = count;
                                                    prenprima++;
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut)))
                                                        prenprima--;
                                                    arr3.push({code:
                                                        <div id={listaprenotazioni[b].ID} onClick={(e) => handleClickPren(e)} className='d-flex' style={{margin: '0 auto'}}>
                                                            <p className='occupiednoborder d-grid' style={{backgroundColor: idcolor, height:'100%', width:'100%', textAlign: 'left'}}>
                                                                <span>Prenotazione #{listaprenotazioni[b].ID}</span>
                                                                <span>Check-In: <br/><b>{YMD(listaprenotazioni[b].DataIn)}</b></span>
                                                            </p>
                                                        </div>, distance: span, count: count, index: i, prenprima: prenprima})
                                                    trovato = true;      
                                                } else if(arrgiorni[i] === listaprenotazioni[b].DataIn && datafine < listaprenotazioni[b].DataOut) {
                                                    //console.log('entro qui PREN INZIA DENTRO SETT E FINISCE DOPO SETT', listaprenotazioni[b].DataIn, listaprenotazioni[b].DataOut, item.Nome, arrgiorni[i], diff+1)
                                                    //ENTRO QUI SE LA PREN INIZIA DENTRO SETT E LA DURATA DELLA PREN FINISCE DOPO LA SETTIMANA CORRENTE)
                                                    if(diff > 0)
                                                        span = diff+1;
                                                    countprima = count;
                                                    prenprima++;
                                                    if(!isNaN(checkinOut(listaprenotazioni[b].RifProprieta, listaprenotazioni[b].DataOut)))
                                                        prenprima--;
                                                    arr3.push({code:
                                                    <div id={listaprenotazioni[b].ID} onClick={(e) => handleClickPren(e)} className='d-flex' style={{margin: '0 auto'}}>
                                                        <p className='occupiednoborder d-grid' style={{backgroundColor: idcolor, height:'100%', width:'100%', textAlign: 'left'}}>
                                                            <span>Prenotazione #{listaprenotazioni[b].ID}</span>
                                                            <span>Check-In: <br/><b className={i===0 ? 'checkin' : ''}>{YMD(listaprenotazioni[b].DataIn)}</b> - {listaprenotazioni[b].OraIn}</span>
                                                        </p>
                                                    </div>, distance: span, count: count, index: i, prenprima: prenprima})
                                                    trovato = true;  
                                                }
                                            }
                                        }
                                    }
                                    if(!span) {
                                      //  console.log('entro vuoto');
                                      if(!count)
                                        arr3.push({code: <td></td>, distance: 1, count: (count ? count-1 : count)});
                                    }
                                }
                                if(trovato) {
                                    //console.log(count, 'conto:');
                                    if(count > 0) {
                                        for(var i = 0; i < arr3.length; i++) {
                                            if(arr3[i].count === 0) {
                                                //console.log('quante volte sono qui?',i, arr3[i]);
                                                arr2.push(<td style={{borderBottom: '0px'}} colSpan={arr3[i].distance}>{arr3[i].code}</td>)
                                                if(arr3[i].prenprima === 0) {
                                                    for(var b = arr3[i].index+arr3[i].distance; b < arrgiorni.length; b++) {
                                                        //console.log('entro quante volte?', b, arr3[i].index+1, arrgiorni.length, arr3[i].prenprima)
                                                        arr2.push(<td></td>)
                                                    }
                                                }
                                            }
                                        }
                                        arr.push(<tr key={index}>{arr2}</tr>)
                                        arr2 = [];
                                       // console.log('sono qui');
                                        for(var i = 1; i <= count; i++) {
                                            let finded = 0;
                                            let distance = 0;
                                            for(var c = 0; c < arr3.length; c++) {
                                                //console.log('sono qui', i,'finded', finded);
                                                if(arr3[c].count === i) {
                                                    for(var b = finded+distance; b < arr3[c].index+1; b++) {
                                                        //console.log('aggiungo vuoto', arr3[c].count, 'finded', finded, 'distance', distance, 'longrange', arr3[c].index+1)
                                                        arr2.push(<td style={{borderTop: '0px'}}></td>)
                                                    }
                                                    //console.log('entro?', arr3[c]);
                                                    arr2.push(<td style={{borderTop: '0px'}} colSpan={arr3[c].distance}>{arr3[c].code}</td>)
                                                    finded = arr3[c].index+1
                                                    distance = arr3[c].distance;
                                                }
                                            }
                                            //console.log('aggiungo tr e svuoto arr2');
                                            arr.push(<tr key={index+'bis'+i}>{arr2}</tr>)
                                            arr2 = []; 
                                        }
                                    } else {
                                        for(var i = 0; i < arr3.length; i++) {
                                            arr2.push(<td colSpan={arr3[i].distance}>{arr3[i].code}</td>)
                                        }
                                        arr.push(<tr key={index}>{arr2}</tr>)
                                    }
                                } else 
                                    arr.push(<tr key={index}>{arr2}</tr>)
                            })
                            return arr;
                            })()}
                        </tbody>
                    </Table>
                </Col>
                {listaprenotazioni.length ? <Col>
                    <h1>Lista Prenotazioni</h1>
                    <Table responsive bordered hover striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Check-In</th>
                                <th>Check-Out</th>
                                <th>Riferimento Casa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                const arr = [];
                                listaprenotazioni.map((item) => {
                                    for(var i = 0; i < listaproprieta.length; i++) {
                                        if(item.RifProprieta === listaproprieta[i].ID) {
                                            if(item.DataIn >= datainizio) {
                                                arr.push(<tr>
                                                    <td><a style={{cursor: 'pointer', textDecoration: 'underline'}} id={item.ID} onClick={(e) => handleClickPren(e)}>{item.ID}</a></td>
                                                    <td>{YMD(item.DataIn)} - {item.OraIn}</td>
                                                    <td>{YMD(item.DataOut)} - {item.OraOut}</td>
                                                    <td>{listaproprieta[i].Nome}</td>
                                                    </tr>)
                                            }
                                        }
                                    }
                                    return false;
                                })
                                return arr;
                            })()}
                        </tbody>
                    </Table>

                </Col> : null}
                </>
                }
            </Row>
        </Container>
        <Modal
            show={modalPren}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => showModalPren(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nuova Prenotazione
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <Form.Select onChange={(e) => setModifyPren({...modifypren, RifProprieta: e.target.value})}>
                                <option value="0">Seleziona casa...</option>
                                {
                                    listaproprieta.map((item) => {
                                        return <option value={item.ID}>{item.Nome}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Nome">
                                <Form.Control
                                    type="text"
                                    value={modifypren.Nome}
                                    placeholder='Nome'
                                    onChange={(e) => setModifyPren({...modifypren, Nome: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem',textAlign: 'right'}} xs={5}>
                            <FloatingLabel label="Cognome">
                                <Form.Control
                                    type="text"
                                    value={modifypren.Cognome}
                                    placeholder='Cognome'
                                    onChange={(e) => setModifyPren({...modifypren, Cognome: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Nazionalità">
                                <Form.Control
                                    type="text"
                                    value={modifypren.Nazionalita}
                                    placeholder='Nazionalità'
                                    onChange={(e) => setModifyPren({...modifypren, Nazionalita: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Cellulare">
                                <Form.Control
                                    type="phone"
                                    value={modifypren.Cellulare}
                                    placeholder='Cellulare'
                                    onChange={(e) => setModifyPren({...modifypren, Cellulare: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Check In">
                                <Form.Control
                                    min={todayDate}
                                    type="date"
                                    value={modifypren.DataIn}
                                    placeholder='Check In'
                                    onChange={(e) => setModifyPren({...modifypren, DataIn: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Ora CheckIn">
                                <Form.Control
                                    type="hour"
                                    value={modifypren.OraIn}
                                    placeholder='Ora CheckIn'
                                    onChange={(e) => setModifyPren({...modifypren, OraIn: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Check Out">
                                <Form.Control
                                    min={todayDate}
                                    type="date"
                                    value={modifypren.DataOut}
                                    placeholder='Check Out'
                                    onChange={(e) => setModifyPren({...modifypren, DataOut: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Ora CheckOut">
                                <Form.Control
                                    type="hour"
                                    value={modifypren.OraOut}
                                    placeholder='Ora CheckOut'
                                    onChange={(e) => setModifyPren({...modifypren, OraOut: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={3}>
                            <FloatingLabel label="Adulti">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Adulti}
                                    placeholder='Adulti'
                                    onChange={(e) => handleNumber(e,"Adulti")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={3}>
                            <FloatingLabel label="Bambini">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Bambini}
                                    placeholder='Bambini'
                                    onChange={(e) => handleNumber(e,"Bambini")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={3}>
                            <FloatingLabel label="Neonati">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Neonati}
                                    placeholder='Neonati'
                                    onChange={(e) => handleNumber(e,"Neonati")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={3}>
                            <FloatingLabel label="Culla">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Culla}
                                    placeholder='Culla'
                                    onChange={(e) => handleNumber(e,"Culla")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={3}>
                            <FloatingLabel label="Seggiolone">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Seggiolone}
                                    placeholder='Seggiolone'
                                    onChange={(e) => handleNumber(e,"Seggiolone")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={3}>
                            <FloatingLabel label="Bagnetto">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Bagnetto}
                                    placeholder='Bagnetto'
                                    onChange={(e) => handleNumber(e,"Bagnetto")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem', marginTop: '1rem'}} xs={3}>
                            <FloatingLabel label="Prezzo">
                                <Form.Control
                                    type="phone"
                                    value={modifypren.Prezzo}
                                    placeholder='Prezzo €'
                                    onChange={(e) => handleNumber(e,"Prezzo")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem', marginTop: '1rem'}} xs={4}>
                            <FloatingLabel label="OTA">
                                <Form.Select value={modifypren.OTA} onChange={(e) => setModifyPren({...modifypren, OTA: e.target.value})}>
                                    <option value="Privato">Privato</option>
                                    <option value="Booking.com">Booking.com</option>
                                    <option value="AirBnb">AirBnb</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        {currentUser?.Admin > 0 ? 
                        <>
                        <Col style={{margin:'0.5rem', marginTop: '1rem'}} xs={3}>
                            <FloatingLabel label="OTA €">
                                <Form.Control
                                    type="phone"
                                    value={modifypren.PercentualeOTA}
                                    placeholder='OTA €'
                                    onChange={(e) => handleNumber(e,"Percentuale")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem', marginTop: '1rem'}} xs={3}>
                            <Form.Check
                                type="checkbox"
                                label="Cedolare secca"
                                checked={modifypren.Cedolare}
                                onChange={(e) => setModifyPren({...modifypren, Cedolare: e.target.checked})}
                            />
                        </Col>
                        {modifypren.Cedolare ? 
                        <Col style={{margin:'0.5rem', marginTop: '0.5rem'}} xs={3}>
                            <Form.Select onChange={(e) => setModifyPren({...modifypren, PercentualeCedolare: e.target.value})}>
                                <option value="21">21%</option>
                                <option value="26">26%</option>
                            </Form.Select>
                        </Col> : null}
                        </> : null
                        }
                        <Col style={{margin:'0.5rem', marginTop: (currentUser?.Admin ? '1rem':'2rem')}} xs={currentUser?.Admin > 0 ? 5 : 3}>
                            <Form.Check
                                type="checkbox"
                                label="Colazione inclusa"
                                checked={modifypren.Colazione}
                                onChange={(e) => setModifyPren({...modifypren, Colazione: e.target.checked})}
                            />
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={10}>
                            <FloatingLabel label="Note">
                                <Form.Control as="textarea"
                                    rows={5}
                                    value={modifypren.Note}
                                    placeholder='Note'
                                    onChange={(e) => setModifyPren({...modifypren, Note: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disabled} variant="success" onClick={handleSavePren}>Salva</Button>
                <Button variant="secondary" onClick={() => showModalPren(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Calendario;