import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import { Person } from 'react-bootstrap-icons';

function Find({tab, rows, show}) {
    const length = rows?.length;
    const handleClick = (e) => {
        const id = e.currentTarget.id;
        show({id: parseInt(id)})
    }
    return (
        <Col xs={12} md={3} className="position-relative text-left">
            <div style={(length ? {borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black'} : null)} tabIndex="0" className="searchDiv">
                {(() => {
                    const arr = [];
                    rows?.map((item, index) => {
                    //    if(item.person)
                            return arr.push(<div style={{ minWidth: 'max-content'}} onClick={handleClick} key={item.ID} id={item.id} className={tab === index ? "findDiv findSelected": "findDiv"}>
                                <div style={{borderRight: '1px solid black', minHeight: '100%'}}><Person/></div><div style={{paddingLeft: '1rem'}}>{item.Nome} {item.Cognome}</div>
                            </div>);
                       /* else if(item.scheda)
                            return arr.push(<div key={item.id} id={item.id} className={tab === index ? "findDiv findSelected": "findDiv"}>
                                <div style={{borderRight: '1px solid black', minHeight: '100%'}}><JournalText/></div><div style={{paddingLeft: '1rem'}}>{item.name}</div>
                            </div>);*/
                    });
                    return arr;
                })()}
            </div>
        </Col>
    );
}

export default Find;